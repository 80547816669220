import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { dialogSliceName } from "../features/dialog/dataTypes";
import dialogSlice from "../features/dialog/dialogSlice";
import { fileSliceName } from "../features/file/dataTypes";
import fileSlice from "../features/file/fileSlice";
import { sessionSliceName } from "../features/session/dataTypes";
import sessionSlice from "../features/session/sessionSlice";
import { signOut } from "./actions";

const combinedReducer = combineReducers({
  [dialogSliceName]: dialogSlice,
  [sessionSliceName]: sessionSlice,
  [fileSliceName]: fileSlice,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (signOut.match(action)) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
